import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ShoppingTitle from '../../components/shoppingTitle';
import OrderStage from '../../components/orderStage';
import OrderForm from '../../components/orderForm';
class Homepage extends Component {
  render() {
    // const price1 = 100;
    // const price2 = 100;
    // const sum = price1 + price2;
    return (
      <Layout showHeader={false} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="title-content">
          <ShoppingTitle title="我的訂單" showActionBtn={true} />
          <OrderStage />
        </div>
        {/* 第一筆訂單 */}
        <div className="product-content">
          <OrderForm
            number="1234567890"
            date="02-02-2020"
            pay="信用卡付款"
            payDate="02-02-2020 12:00"
            account="1234567890"
            shipDate="未定"
            invoice="電子發票"
          />
        </div>

        <div className="divider"></div>

        {/* 第二筆訂單 */}
        <div className="product-content">
          <OrderForm
            number="1234567890"
            date="02-02-2020"
            pay="信用卡付款"
            payDate="02-02-2020 12:00"
            account="1234567890"
            shipDate="未定"
            invoice="電子發票"
          />
        </div>
        <div className="divider"></div>

        <style jsx>{`
          .title-content {
            margin: 30px 16px 0 16px;
          }

          .product-content {
            padding: 15px 0 15px 0;
            margin: 0px 16px;
            min-width: 343px;
          }

          .divider {
            height: 10px;
            background-color: #e0e0e0;
          }

          hr {
            border: none;
            height: 1px;
            background-color: #e0e0e0;
            min-width: 343px;
            margin: auto;
          }

          .price-content {
            padding: 15px 0 30px 0;
            margin: 0px 16px;
            min-width: 343px;
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
