import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';
import OrderFormBtnList from '../components/orderFormBtnList';

const OrderForm = props => {
  const { number, date, pay, account, shipDate, invoice, payDate } = props;

  return (
    <div className="content">
      <div className="info-content">訂單編號：　　　{number}</div>
      <div className="info-content">訂購日期：　　　{date}</div>

      {/* 付款方式有三種：信用卡、ATM、ibon */}
      <div className="info-content">付款方式：　　　{pay}</div>
      <div className="info-content">付款方式：　　　ATM 轉帳</div>
      <div className="pay-date">
        (付款日：
        <span className="prompt-content">{payDate} </span>
        以前)
      </div>
      <div className="info-content">付款方式：　　　7-11 ibon付款</div>
      <div className="pay-date">
        (付款日：
        <span className="prompt-content">{payDate} </span>
        以前)
      </div>

      {/* 付款帳號、繳費代碼吃相同樣式 */}
      <div className="info-content">
        付款帳號：　　　<span className="account-content">{account}</span>
      </div>
      <div className="info-content">
        繳費代碼：　　　<span className="account-content">{account}</span>
      </div>

      <div className="info-content">預計出貨日期：　{shipDate}</div>
      <div className="info-content">
        發票：　　　　　<span className="invoice-content">{invoice}</span>
      </div>
      <OrderFormBtnList />

      <style jsx>{`
        .content {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
        }

        .info-content {
          padding: 8px 0;
        }

        .pay-date {
          font-size: 12px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          margin-left: 110px;
          margin-bottom: 10px;
        }

        .prompt-content {
          color: #ff0080;
        }

        .account-content {
          color: ${colors['purple']};
        }

        .invoice-content {
          color: #e0e0e0;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

OrderForm.propTypes = {
  number: PropTypes.string,
  date: PropTypes.string,
  pay: PropTypes.string,
  account: PropTypes.string,
  shipDate: PropTypes.string,
  invoice: PropTypes.string,
};

OrderForm.defaultProps = {
  number: '',
  date: '',
  pay: '',
  account: '',
  shipDate: '',
  invoice: '',
};

export default OrderForm;
