import React from 'react';
import colors from '../config/color';

const OrderStage = () => {
  return (
    <div className="content">
      <div className="stage-btn">
        <div className="btn-sty active-btn">進行中</div>
        <div className="btn-sty">已完成</div>
      </div>

      <style jsx>{`
        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 18px;
          position: relative;
        }

        .stage-btn {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          background-color: #e0e0e0;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        .btn-sty {
          padding: 5px 30px;
        }

        .active-btn {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['purple']};
          background-color: ${colors['white']};
          border: 1px solid ${colors['purple']};
          border-radius: 5px;
          padding: 5px 30px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

OrderStage.propTypes = {};

OrderStage.defaultProps = {};

export default OrderStage;
