import React from 'react';
import PropTypes from 'prop-types';
import OrderFormBtn from '../components/orderFormBtn';
import colors from '../config/color';

const OrderFormBtnList = () => {
  return (
    <div className="content">
      <div className="sty">
        <OrderFormBtn text="訂單詳情" />
      </div>
      <div className="sty">
        <OrderFormBtn text="訂單詳情" />
      </div>
      <div className="sty">
        <OrderFormBtn text="訂單詳情" />
      </div>
      <div>
        <OrderFormBtn text="訂單詳情" />
      </div>

      <style jsx>{`
        .content {
          margin-top: 10px;
          padding: 7px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }

        .sty {
          margin-right: 8.5px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

OrderFormBtnList.propTypes = {};

OrderFormBtnList.defaultProps = {};

export default OrderFormBtnList;
